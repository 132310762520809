import React from "react"
import PropTypes from "prop-types"
import Layout from "../components/Layout/Layout"
import GlobalContext from "../context/GlobalContext"
import { setDataLayer, trackPageView } from "../helpers/dataLayerSetter"
import ContentfulComponents from "../helpers/ContentfulComponents"
import styles from "../components/ProductCategoryPage/ProductCategoryPage-tw-styles"
import { gTagScriptConsent } from "../components/Scripts/Gtm.Head"

const ProductCategoryPage = ({ path, pageContext }) => {
    const { globalComponents, seoData, dataLayer, globalLabels, category } = pageContext

    setDataLayer(dataLayer, seoData, "Product Category Page")
    trackPageView(path)

    return (
        <GlobalContext labels={globalLabels}>
            <Layout seoData={seoData} globalComponents={globalComponents}>
                <div className={styles?.productCategoryPageWrapper}>
                    <ContentfulComponents componentsProps={category?.topContent} />
                    <div className={styles?.middleBackgroundImgWrapper}>
                        <ContentfulComponents componentsProps={category?.middleContent} />
                    </div>
                    <ContentfulComponents componentsProps={category?.bottomContent} />
                </div>
            </Layout>
        </GlobalContext>
    )
}

export const Head = () => {
    const dataObject = typeof window !== "undefined" ? window?.gtmDataLayer : {}
    return (
        <>
            <script
                id="first-unique-id"
                type="text/javascript"
                dangerouslySetInnerHTML={{
                    __html: `var MdlzdataLayer = ${JSON.stringify(dataObject)};`,
                }}
            />
            <script
                async
                type="text/javascript"
                dangerouslySetInnerHTML={{ __html: gTagScriptConsent }}
            />
        </>
    )
}

ProductCategoryPage.propTypes = {
    path: PropTypes.string,
    pageContext: PropTypes.shape({
        globalComponents: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
        dataLayer: PropTypes.object,
        seoData: PropTypes.object,
        category: PropTypes.shape({
            topContent: PropTypes.array,
            middleContent: PropTypes.array,
            bottomContent: PropTypes.array,
        }),
        globalLabels: PropTypes.object,
    }),
}

export default ProductCategoryPage
